<template>
  <v-content>
    <v-container grid-list-lg fluid style="margin-bottom: 58px;">
      <v-layout wrap align-start justify-start fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title title="Vista de precios de los productos">
            <template slot="subtitle">
              Hay
              <span class="font-weight-bold">{{ products.length }}</span>
              productos listados
            </template>
            <template slot="button">
              <v-menu v-model="menu" :close-on-content-click="false" bottom left min-width="300">
                <v-btn slot="activator" flat icon class="mt-0">
                  <v-icon>more_vert</v-icon>
                </v-btn>
                <v-card>
                  <v-card-title class="title">Filtros</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-checkbox v-model="productsFilter.sale" label="Solo ofertas"></v-checkbox>
                    <v-checkbox v-model="productsFilter.on" label="Activos"></v-checkbox>
                    <v-checkbox v-model="productsFilter.off" label="Inactivos"></v-checkbox>
                    <v-checkbox v-model="productsFilter.hidden" label="Deshabilitados"></v-checkbox>
                    <v-combobox
                      v-model="categories"
                      :items="categoriesAll"
                      item-text="name"
                      item-value="id"
                      label="Categorias"
                      multiple
                      chips
                      clearable
                      small-chips
                    ></v-combobox>
                    <v-select
                      v-model="productsFilter.price"
                      :items="[
                        { id: 'desc', name: 'Precio de mayor a menor' },
                        { id: 'asc', name: 'Precio de menor a mayor' }
                      ]"
                      item-value="id"
                      item-text="name"
                      label="Ordenar por:"
                    ></v-select>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="primary" depressed @click="getProducts">
                      Filtrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
          </app-title>
        </v-flex>
        <v-flex xs12 sm6>
          <v-card class="mb-3">
            <v-card-text>
              <v-layout row wrap>
                <v-flex text-xs-left>
                  <v-icon size="68" class="ma-2"> waves </v-icon>
                </v-flex>
                <v-flex text-xs-right>
                  <h2 class="display-3" style="font-weight: 300;">
                    {{ totalProducts }}
                  </h2>
                  <h3 class="subheading">Total filtrados</h3>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6>
          <v-card class="mb-3">
            <v-card-text>
              <v-layout row wrap>
                <v-flex text-xs-left>
                  <v-icon size="68" class="ma-2"> attach_money </v-icon>
                </v-flex>
                <v-flex text-xs-right>
                  <h2 class="display-3" style="font-weight: 300;">
                    {{ promProducts | toPrice }}
                  </h2>
                  <h3 class="subheading">Promedio de precios</h3>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 lg12 xl12>
          <v-timeline>
            <v-timeline-item v-for="(item, n) in items" :key="n" :color="item.status ? 'success' : 'error'" large small>
              <div slot="opposite">
                <router-link :to="{ name: 'product', params: { id: item.id } }" style="text-decoration: none;">
                  <p class="title font-weight-regular" v-html="item.name"></p>
                </router-link>
                <p class="caption grey--text text--darken-1" v-html="`Codigo: P${item.id}`"></p>
                <v-chip
                  v-for="(category, index) in item.categories"
                  :key="index"
                  color="secondary"
                  class="ml-0 white--text"
                  small
                  label
                  @click="clickCategory(category)"
                >
                  {{ category.name }}
                </v-chip>
              </div>
              <v-card class="elevation-2">
                <v-card-text>
                  <v-layout>
                    <v-flex xs4>
                      <router-link :to="{ name: 'product', params: { id: item.id } }">
                        <v-img
                          :src="item.img.url"
                          :lazy-src="`https://picsum.photos/10/6`"
                          class="lighten-2"
                          width="100%"
                        />
                      </router-link>
                    </v-flex>
                    <v-flex xs8>
                      <template v-if="item.sale !== undefined">
                        <p class="headline mb-0">Oferta: {{ item.sale.price | toPrice }}</p>
                        <p class="subheading">Normal: {{ item.price | toPrice }}</p>
                        <p v-if="item.sale.status_quantity" class="body-1 mb-0">
                          Cantidad en oferta: {{ item.sale.quantity }}
                        </p>
                        <p v-if="item.sale.status_date" class="body-1">
                          Expira el
                          <span class="error--text">{{ item.sale.date }}</span>
                        </p>
                      </template>
                      <template v-else>
                        <p class="headline">Precio: {{ item.price | toPrice }}</p>
                      </template>
                      <p class="body-1" v-html="item.description"></p>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>

          <InfiniteLoading v-if="showInfinite" @infinite="getItems">
            <div slot="no-more" class="pt-2 pb-0">No hay productos</div>
            <div slot="no-results" class="pt-2 pb-0">No hay resultados</div>
          </InfiniteLoading>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import appTitle from '../useful/title.vue'
import { GET_API, GET_PRODUCTS_ALL } from '../../config'

export default {
  name: 'Products',
  components: { appTitle, InfiniteLoading },
  props: ['params'],
  metaInfo: {
    title: 'Productos'
  },
  data: () => ({
    loading: false,
    menu: false,
    productsAll: [],
    categoriesAll: [],
    products: [],
    categories: [],
    items: [],
    paginate: 0,
    pagination: { rowsPerPage: 50 },
    showInfinite: false,
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    productsFilter: {
      on: true,
      off: false,
      hidden: false,
      price: 'asc',
      sale: false
    },
    searchText: '',
    headers: [
      {
        text: 'Foto',
        align: 'left',
        sortable: false,
        value: 'photo'
      },
      {
        text: 'Nombre',
        align: 'left',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Estado',
        align: 'left',
        sortable: false,
        value: 'status'
      }
    ]
  }),
  computed: {
    productsPaginate() {
      return this._.chunk(this.products, 20)
    },
    totalProducts() {
      return this.products.length
    },
    promProducts() {
      const prices = this._.sumBy(this.products, 'price')
      return prices / this.products.length
    },
    user() {
      return this.$store.getters.getUser
    }
  },
  watch: {
    '$route.query': function(e) {
      this.productsFilter.sale = e.sale !== undefined ? true : false
      this.getProducts()
    }
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    async getProducts() {
      this.showInfinite = false
      this.items = []
      try {
        const res = await this.$http.get(GET_PRODUCTS_ALL, {
          params: this.productsFilter
        })
        this.paginate = 0
        this.productsAll = res.data
        this.products = res.data
        const filterOrder = []
        this.productsAll.forEach((el, index) => {
          this.productsAll[index].categoriesIds = []
          el.categories.forEach(category => {
            filterOrder.push({
              id: category.id,
              name: category.name
            })
            this.productsAll[index].categoriesIds.push(category.id)
          })
        })
        this.categoriesAll = this._.orderBy(this._.uniqBy(filterOrder, 'id'), ['name'], ['asc'])
        if (this.categories.length > 0) {
          const categories = []
          this.categories.forEach(el => {
            categories.push(el.id)
          })
          const products = this._.filter(this.productsAll, item => {
            const int = this._.intersection(item.categoriesIds, categories)
            return int.length > 0
          })
          this.products = products
        }
        this.menu = false
        this.products = this._.orderBy(this.products, ['price'], [this.productsFilter.price])
        this.productsAll = this._.orderBy(this.products, ['price'], [this.productsFilter.price])
        this.showInfinite = true
      } catch (error) {}
    },
    getItems($state) {
      const self = this
      console.log(this.products.length, this.productsPaginate.length, self.items.length)
      if (this.products.length === self.items.length) {
        if ($state) {
          $state.complete()
        }
      } else {
        this.productsPaginate[self.paginate].forEach(element => {
          self.items.push(element)
        })
        self.paginate += 1
        if ($state) {
          $state.loaded()
        }
      }
    },
    changeProduct(id, status, item) {
      item.status = !item.status
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'changeStatusProduct',
          id,
          status
        }
      }).then(res => {
        const key = this._.findKey(this.products, { id })
        console.log(res, key)
      })
    },
    goProduct(route, id) {
      this.$router.push({ name: route, params: { id } })
    },
    searchInputHandle() {
      const search = this.searchText
      let result = this._.filter(this.productsAll, item => {
        const res = this._.includes(this._.lowerCase(item.name), this._.lowerCase(search))
        return res
      })
      if (result.length === 0) {
        result = this._.filter(this.productsAll, item => {
          const res = this._.includes(this._.lowerCase(item.id), this._.lowerCase(search))
          return res
        })
      }
      if (result.length > 0) {
        this.products = result
      } else {
        this.products = []
      }
      this.dialogSearch = false
    },
    clickCategory(item) {
      this.categories.push(item)
      this.getProducts()
    }
  }
}
</script>
